import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { startWith, tap } from 'rxjs/operators';
import { trace } from '@angular/fire/performance';
import { DbService } from 'src/core/services/common/db/db.service';
import { APIService } from './service/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'OkrNetwork';
  networkslist: any[] = [];
  userName: string = "";
  email: string = "";
  number: number = 0;

  constructor( 
    private readonly db: DbService,
    private readonly apiService: APIService
    ) {
    
  }

  ngOnInit(): void {
    this.getNetworks();
  }

  checkPersistance() {
    this.db.getPersistenceEnabled().subscribe((pers) => {
      console.log("Is Persistance Enabled ==> ", pers)
    })
  }

  getNetworks() {
    this.db.getCollection$('okrnetwork', queryFn => queryFn.orderBy('ds', 'desc').limit(25)).snapshotChanges().subscribe((result) => {
      this.networkslist = result.map(d => d.payload.doc.data());
      console.log("Got User List Snapshot");
      this.checkPersistance();
    })
  }

  getAllNetworks() {
    this.db.getCollection$('okrnetwork', queryFn => queryFn.orderBy('ds', 'desc')).get().toPromise().then((result) => {
      this.networkslist = result.docs.map(d => d.data());
      console.log("Got All User List");
      this.checkPersistance();
    })
  }

  saveUserNetwork() {
    this.db.saveDocumet$('okrnetwork', { userName: this.userName, address: this.email }, this.number).then(() => {
      this.userName = "";
      this.email = "";
      this.number = 0;
      console.log("Document Added");
    })
  }

  saveUserNetworkWithAPI(){
    let doc={"fields":
      { 
        userName: {'stringValue':'API-' + this.userName}, 
        address: {'stringValue':this.email}, 
        ds : {'timestampValue':new Date()} 
      }
    };
    // this.apiService.callGCPFireStoreAPI('',doc);
    for (let i = 0; i <  this.number; i++) {
      this.apiService.callGCPFireStoreAPI('',doc);
    }
  }
}
