import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class APIService {
  constructor(private readonly http: HttpClient) {}

  async callGCPFireStoreAPI(endPoint: string, input: any) {
    return new Promise((resolve, reject) => {
      const apiURL = `https://firestore.googleapis.com/v1/projects/prj-wmte-v2/databases/(default)/documents/okrnetwork/`;
      this.http
        .post(apiURL, 
          
          input
          , {
          headers: {
            'Content-Type': 'application/json'
            // Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        })
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

}
