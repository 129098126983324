import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  QueryFn,
} from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;
type DocumentPredicate<T> = string | AngularFirestoreDocument<T>;

@Injectable({
  providedIn: 'root',
})
export class DbService {

  constructor(
    private readonly db: AngularFirestore,
) {}

  getPersistenceEnabled() {
      return this.db.persistenceEnabled$
    }

  ///**************
  /// Get A Reference
  ///**************
  collection<T>(
    ref: CollectionPredicate<T>,
    queryFn?: QueryFn
  ): AngularFirestoreCollection<T> {
    return typeof ref === 'string' ? this.db.collection<T>(ref, queryFn) : ref;
    try {
    }
    catch(error)
    {
    }
  }

  document<T>(ref: DocumentPredicate<T>): AngularFirestoreDocument<T> {
    return typeof ref === 'string' ? this.db.doc<T>(ref) : ref;
    try {
    }
    catch(error){
    }
  }

  ///**************
  /// Get Data
  ///**************
  getDocument$<T>(ref: DocumentPredicate<T>): Observable<T> {
    return this.document(ref)
    .get()
    .pipe(
      map((doc) => {
        return doc.data() as T;
      })
      );
      try{
    }
    catch(error){
    }
  }
  ///**************
  /// Get Data without snapshot changes
  ///**************
  getDocumentPromise$<T>(
    ref: DocumentPredicate<T>
  ) {
    return this.document(ref)
    .get()
    .toPromise()
    .then((doc) =>{
      const data : any = doc.data() 
      return data;
    });
    try{
  }
    catch(error){
    }
  }

  getCollection$<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn): AngularFirestoreCollection<T> {
    return this.collection(ref, queryFn)
  }

  saveDocumet$<T>(ref: string, data: any, count = 1) {
    const batch = firebase.default.firestore().batch();
    for (let i = 0; i < count; i++) {
      const jobRef = this.db.collection(ref).doc().ref;
      data["ds"] = firebase.default.firestore.Timestamp.fromDate(new Date());
      batch.set(jobRef, data);
    }
    return batch.commit();
  }

  documentToDomainObject = (_: any) => {
    try {
      const object = _.payload.doc.data();
      object.id = _.payload.doc.id;
      object.path = _.payload.doc.ref.path;
      return object;
    }
    catch (error) {
    }
  };
}
