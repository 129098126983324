import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { FormsModule } from '@angular/forms';
import { UserTimezonePipe } from 'src/shared/pipe/user-timezone.pipe';
import { AngularFirestoreModule, SETTINGS as FIRESTORE_SETTINGS } from '@angular/fire/firestore';
import { I18nModule } from './service/i18n/i18n.module';

@NgModule({
  declarations: [
    AppComponent,
    UserTimezonePipe
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule, FormsModule
    ,I18nModule
  ],
  exports:[
    I18nModule
  ],
  providers: [
    // { provide: FIRESTORE_SETTINGS, useValue: { experimentalForceLongPolling: true } },  //experimentalAutoDetectLongPolling //To remove 10 sec restriction on request
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
