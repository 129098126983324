// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  i18nPrefix: '',

  // firebase: {
  //   apiKey: "AIzaSyDfFWj2z02HA6CE10WRH46GI2tHfjUAXJw",
  //   authDomain: "prj-wmte-b-349009.firebaseapp.com",
  //   projectId: "prj-wmte-b-349009",
  //   storageBucket: "prj-wmte-b-349009.appspot.com",
  //   messagingSenderId: "699604852546",
  //   appId: "1:699604852546:web:496cb73bb9c2bc7d5cf2db",
  //   measurementId: "G-G2VHM2DTEZ"
  // },
  // firebase: {
  //   apiKey: "AIzaSyA4Kzb8UXc3EIG0jOojGbvVq6Hj33gLChw",
  //   projectId: "prj-wmte-v2",
  //   storageBucket: "prj-wmte-v2.appspot.com",
  //   appId: "1:229264054203:web:f49322405943e4870a169e",
  // },
  firebase: {
    apiKey: "AIzaSyAbBMx25J4tTs64OpkHRZOshpmNcDEKmo4",
    authDomain: "prj-wmte-v2-1.firebaseapp.com",
    projectId: "prj-wmte-v2-1",
    storageBucket: "prj-wmte-v2-1.appspot.com",
    messagingSenderId: "648738489385",
    appId: "1:648738489385:web:6922321dcf3840292bc838",
    measurementId: "G-DB4J374T14"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
