import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userTimezone'
})
export class UserTimezonePipe implements PipeTransform {

  transform(value: any): any {
    var date = value; //*1000
    var targetTime = new Date(date.seconds*1000); 
    return new Date(targetTime.getTime());
  }


}
