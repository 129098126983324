<div class="wm-container">

  <div class="wm-auth-page-panel">
    <div class="row col-24">
      <div class="wm-logo">
        <img alt="witsmate" src="../../../assets/wm-witsmate-web.svg" height="40" />
      </div>
    </div>
    <div class="row col-24">
    <div class="row col-24">
      <div class="col-6">
        User name
      </div>
      <div class="col-6">
        <input type="text" [(ngModel)]="userName" placeholder="Username">
      </div>
    </div>

    <div class="row col-24">
      <div class="col-6">
        User Email
      </div>
    <div class="col-6">
      <input type="text" [(ngModel)]="email" placeholder="Email">
    </div>
    </div>

    <div class="row col-24">
      <div class="col-6">
        Number of record to insert
      </div>
    <div class="col-6">
      <input type="number" [(ngModel)]="number" placeholder="Number">
    </div>
    </div>

    
    <div class="row col-24">
      <div class="row col-24">
        <button (click)="saveUserNetwork()">Save User</button>
      </div>
      <div class="row col-24">
        <button (click)="saveUserNetworkWithAPI()">Save with API</button>
      </div>
      <div class="row col-24">
        <button (click)="getAllNetworks()">Show All</button>
      </div>
    </div>
  </div>

  <div style="margin-bottom: 50px;">
    Number of records : {{networkslist?.length}}
  </div>
  
    <div class="wm-text-center wm-ptb-4 wm-label-2-regular">
      <div class="row col-24">
        <div class="col-2">Sr No</div>
        <div class="col-6">User Name</div>
        <div class="col-6">Address</div>
        <div class="col">Date</div>
      </div>
      <div class="row col-24" *ngFor="let user of networkslist; index as i; first as isFirst">
        <div class="col-2">{{i}}.</div>
        <div class="col-6">{{user.userName}}</div>
        <div class="col-6">{{user.address}}</div>
        <div class="col">
          <span style="font-weight: bold;" *ngIf="user.ds!=null">
            {{ user.ds | userTimezone | date:'dd/MM/yyyy'}}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- <p>
  Firestore!
  <input type="text" [(ngModel)]="userName" placeholder="Username">
  <input type="text" [(ngModel)]="address" placeholder="Address">
  <input type="number" [(ngModel)]="number" placeholder="Number">
  <button (click)="saveUserNetwork()">Save User</button>
  <button (click)="getAllNetworks()">Show All</button>
  <li *ngFor="let user of networkslist; index as i; first as isFirst">
    {{i}}. {{user.userName}} {{user.address}} <span style="font-weight: bold;" *ngIf="user.ds!=null">
      {{ user.ds | userTimezone | date:'dd/MM/yyyy'}}
    </span>
  </li>
</p> -->